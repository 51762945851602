<template>
  <div class="username-selector" ref="usernameSelector">
      <a-text as="h6" :id="title ? 'username-selector-title': ''">{{ title }}</a-text>
      <a-tabs
        v-if="showTabs"
        class="username-tab-bar"
        :selected="selectedTab"
        :aria-labelledby="title ? 'username-selector-title': 'sign-in-title'"
        :tabs="tabs"
        @switchTabs="onSwitchTab"
      />

      <div v-if="selectedTab === 'phone'" id="phone-tabpanel" class="phone-container">
        <reactive-phone-input
          v-model="username"
          :inputWidth="inputWidth"
          required
          :readonly="readOnly"
          @update:modelValue="onUsernameChange($event)"
        />
      </div>
      <div v-if="selectedTab === 'email'" id="email-tabpanel">
        <a-form-input
          id="username"    
          v-model.trim="username"
          :label="$t('shared.email')"
          :aria-label="$t('shared.email')"
          :placeholder="$t('shared.placeholders.typeEmail')"
          :inputWidth="inputWidth"
          :readonly="readOnly"
          :show-error="showValidationMessage && !username.length"
          :error-message="$t('signUp.errors.emailBlank')"
          required
          @update:modelValue="onUsernameChange($event)"
          @invalid.prevent="showValidationMessage = true"
        />
        <a-form-input
          v-if="confirm"
          id="email-confirm"
          class="email-confirm"
          :inputWidth="inputWidth"
          v-model.trim="username_confirm"
          :label="$t('shared.confirmEmail')"
          :show-error="showValidationMessage && !username_confirm.length"
          :error-message="$t('signUp.errors.emailBlank')"
          required
          :placeholder="$t('shared.placeholders.confirmEmail')"
          @update:modelValue="onUsernameConfirmChange($event)"
          @invalid.prevent="showValidationMessage = true"
        />
      </div>

  </div>
</template>

<script>
import ReactivePhoneInput from "../ReactivePhoneInput.vue"
export default {
  name: "UsernameSelector",
  components: {
    ReactivePhoneInput
  },
  props: {
    title: {
      type: String,
      default: "Select a username",
    },
    showTabs: {
      type: Boolean,
      required: false,
      default: true,
    },
    confirm: {
      type: Boolean,
      required: false,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    inputWidth: {
      type: String,
      default: '200px',
    },
  },
  data() {
    return {
      tabs: [
        {
          label: 'Email',
          value: 'email',
          ariaControls: 'email-tabpanel',
        },
        {
          label: 'Phone',
          value: 'phone',
          ariaControls: 'phone-tabpanel',
        },
      ],
      selectedTab: 'email',
      usernameType: null,
      username: "",
      username_confirm: "",
      showValidationMessage: false,
    }
  },
  beforeMount() {
    this.selectedTab = this.type
    this.username = this.value
  },
  mounted() {
    // The current version of the tab component uses <button> elements for the tabs.
    // By default buttons have a type of 'submit', which can cause HTML forms to be submitted if you
    // Put this component on a standard HTML form. This workaround will change the type to 'button'
    // So that the form is not submitted.
    // TODO: Once https://github.com/apptegy/components/pull/665/files is merged, we can remove this
    this.$refs.usernameSelector.querySelectorAll('button').forEach((button) => {
      button.setAttribute('type', 'button')
    })
  },
  methods: {
    onSwitchTab(event) {
      this.selectedTab = event.target.value;
      this.username = "";
      this.$emit('update:modelValue', "");
      this.$emit('type-changed', this.selectedTab);
    },
    onUsernameChange(newValue) {
      this.$emit('update:modelValue', newValue);
    },
    onUsernameConfirmChange(newValue) {
      this.$emit('confirm-input', newValue);
    },
  },
}
</script>

<style lang="scss">
  .username-selector {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
  }
  .username-tab-bar {
    align-self: center;
    margin-bottom: 1rem;
  }
  .input-username-phone {
    align-self: center;
  }
  .phone-container {
    display: flex;
    justify-content: center;
  }
  .email-confirm {
    margin-top: 1rem;
  }
</style>
